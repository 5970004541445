<template>
  <div class="block-title-div">
    <ElText size="large">{{  props.title }}</ElText>
    <span class="block-desc">{{ props.description }}</span>
  </div>

</template>
<script setup lang="ts">
import { defineProps } from 'vue'

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  description: {
    type: String
  }
})
</script>
<style lang="scss" scoped>
.block-title-div {
  width: 180px;
  border-right: 2px solid var(--el-border-color-light);
  margin-right: 40px;
}

.block-title-div .el-text {

}
span.block-desc {
  display: block;
  font-size: 14px;
  color: var(--el-text-color-secondary);
  padding: 12px 12px 0px 0px;
  text-align: left;
}
</style>
