<template>
<BlockTitle title="Data Points" description="Edit the data points supported by the device to ensure proper conversion into BACnet Data Objects by the BACnet gateway." />
<div style="display: block;width:900px">
<ElForm ref="newDataPointForm" :rules="DataPointFormRules" :model="newDataPoint" label-width="auto" label-position="left" style="width:600px">
  <ElFormItem label="Channel" >
    <ElInputNumber v-model="newDataPoint.channel" readonly="true"/>
  </ElFormItem>
  <ElFormItem label="Name" prop="name" >
    <ElInput v-model="newDataPoint.name" />
  </ElFormItem>
  <ElFormItem label="BACnet Object Type" >
    <ElSelect v-model="newDataPoint.type" >
      <ElOption v-for="opt in BACObjTypeOptions" :key="opt.value" :value="opt.value" :label="opt.name" />
    </ElSelect>
  </ElFormItem>
  <ElFormItem label="Engineer Unit" >
    <ElSelect v-model="newDataPoint.units" filterable >
      <ElOption v-for="opt in BACUnitsOptions" :key="opt.value" :value="opt.value" :label="opt.name" />
    </ElSelect>
  </ElFormItem>
  <ElFormItem label="CoV Increment" >
    <ElInputNumber v-model="newDataPoint.covIncrement" />
  </ElFormItem>
  <ElFormItem label="Downlink FPort" prop="fport" v-if="isWritable(newDataPoint.type)" >
    <ElInputNumber v-model="newDataPoint.fport" />
  </ElFormItem>
  <ElFormItem label="Update Interval (Seconds)" prop="updateInterval"  >
    <ElInputNumber v-model="newDataPoint.updateInterval"/>
  </ElFormItem>
  <ElFormItem label=" ">
    <ElButton type="primary" :icon="Plus" @click="OnAddDataPoint()" :disabled="disableNewDataPoint" >Add BACnet Object</ElButton>
  </ElFormItem>
</ElForm>
<ElTable :data="data" style="width: 900px" stripe table-layout="auto">
  <ElTableColumn type="selection" />
  <ElTableColumn prop="channel"  label="Channel" width="80" />
  <ElTableColumn prop="name"  label="Name" width="250"  >
    <template #default="scope">
      <ElInput v-model="scope.row.name" />
    </template>
  </ElTableColumn>
  <ElTableColumn prop="type"  label="Object Type"  />

  <ElTableColumn prop="covIncrement"  label="Cov Increment"  />
  <ElTableColumn prop="fport"  label="Downlink FPort"  />
  <ElTableColumn prop="updateInterval"  label="Update Interval"  />
  <ElTableColumn prop="units"  label="Unit"  />
  <ElTableColumn label="" width="100" >
    <template #default="scope">
      <ElIcon @click="OnDeleteDataPoint(scope.row.channel)" style="margin-right:8px">
        <Delete />
      </ElIcon>
      <ElIcon @click="OnDuplicate(scope.row)">
        <CopyDocument />
      </ElIcon>
    </template>
  </ElTableColumn>
</ElTable>
</div>

</template>
<script setup lang="ts">
import { ElOption, ElSelect, ElTable, FormInstance, FormRules } from 'element-plus'
import { computed, onMounted, reactive, ref } from 'vue'
import { DataPointItem } from '@/store/profile.interface'
import { Plus, Delete, CopyDocument } from '@element-plus/icons-vue'
import { BACObjectType, BACObjectTypeMap, EngineeringUnits } from '@/store/bactype'
import { NewProfileStore } from '@/store/newProfileStore'
import BlockTitle from './BlockTitle.vue'

const store = new NewProfileStore()

const data = computed(() => store.DataPoints())

const newDataPointForm = ref<FormInstance>()
const disableNewDataPoint = ref<boolean>(false)

interface SelectOptions {
  name: string,
  value: string
}

const ListBACObjTypeOptions = () :SelectOptions[] => {
  return Object.values(BACObjectType).map(type => {
    return {
      name: type,
      value: type
    }
  })
}

const isWritable = (type: BACObjectTypeMap[keyof BACObjectTypeMap]):boolean => {
  switch (type) {
    case BACObjectType.ANALOG_VALUE:
    case BACObjectType.ANALOG_OUTPUT:
    case BACObjectType.BINARY_OUTPUT:
    case BACObjectType.BINARY_VALUE:
    case BACObjectType.OCTET_STRING_VALUE:return true
    default: return false
  }
}

const ListUnitsOptions = (): SelectOptions[] => {
  return Object.values(EngineeringUnits).map(type => {
    return {
      name: type,
      value: type
    }
  })
}

const BACObjTypeOptions = ref<SelectOptions[]>(ListBACObjTypeOptions())
const BACUnitsOptions = ref<SelectOptions[]>(ListUnitsOptions())

const newDataPoint = reactive<DataPointItem>({
  channel: 1,
  name: '',
  type: 'AnalogInputObject',
  covIncrement: 0,
  fport: undefined,
  updateInterval: 60,
  units: undefined
})

const DataPointFormRules = ref<FormRules<DataPointItem>>({
  name: [{
    type: 'string', min: 1, max: 32, required: true, pattern: /^[a-zA-Z0-9_-\s]{1,32}$/, trigger: 'blur'
  }],
  fport: [{
    type: 'integer', min: 1, max: 224, required: true
  }],
  updateInterval: [{
    type: 'integer', min: 1, max: 86400, required: true
  }]
})

const OnAddDataPoint = () => {
  newDataPointForm.value?.validate((isvalid, item) => {
    if (isvalid) {
      store.PushDataPoint({ ...newDataPoint }).then(() => {
        ResetNewDataPointForm()
      }).catch(reason => {
        console.log(reason)
      })
    }
  })
}

const OnDeleteDataPoint = (channel:number) => {
  store.DeleteDataPoint(channel).then(() => {
    ResetNewDataPointForm()
  }).catch(err => {
    console.log(err)
  })
}
const ResetNewDataPointForm = () => {
  const channelId = store.GetNextChannelID()
  if (channelId === undefined) {
    disableNewDataPoint.value = true
    return
  } else {
    disableNewDataPoint.value = false
  }

  newDataPoint.channel = channelId
  newDataPoint.name = ''
  newDataPoint.covIncrement = 0
  newDataPoint.type = BACObjectType.ANALOG_INPUT
}

const OnDuplicate = (item: DataPointItem) => {
  const New = { ...item }
  New.channel = store.GetNextChannelID()
  store.PushDataPoint({ ...New }).then(() => {
    ResetNewDataPointForm()
  }).catch(reason => {
    console.log(reason)
  })
}

onMounted(() => {
  ResetNewDataPointForm()
})
</script>
