import { createStore } from 'vuex'
import newProfileStore from './newProfileStore'
import profiles from './profiles'
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    newProfileStore,
    profiles
  }
})
