<template>
  <ElRow v-loading="loading" >
    <ElCol :span="24">
      <ElRow v-if="step === CreateProfleStep.STEP_TYPE">
        <ElCol :span="24">
        <CreateMethod @selected="step += 1" />
      </ElCol>
      </ElRow>

      <ElRow v-if="step === CreateProfleStep.STEP_META">
        <ElCol :span="24">
          <div style="display: flex; padding: 24px; justify-content: start;">
            <ProfileMetaForm ref="metaRef" />
          </div>
          <div style="display: flex; padding: 24px; justify-content: start;">
            <ProfileLoRaWANForm ref="lorawanRef" />
          </div>
        </ElCol>
      </ElRow>

      <ElRow v-if="step === CreateProfleStep.STEP_DP">
        <ElCol :span="24">
          <div style="display: flex; padding: 24px; justify-content: start;">
            <DataPointEditor ref="DataPointEditorRef" />
          </div>
          <div style="display: flex; flex:1;padding: 24px; justify-content: start;">
            <CodeEditor ref="CodeCEditorRef" />
          </div>
        </ElCol>
      </ElRow>

      <ElRow v-if="step === CreateProfleStep.STEP_VERIFY">
        <ElCol :span="24">
            <CodecValidator ref="CodeValidatorRef" />

        </ElCol>
      </ElRow>

      <ElRow v-if="step === CreateProfleStep.STEP_DONE">
        <ElCol :span="24">
        <div style=" flex:1; padding: 24px; justify-content: start;">
          <el-result
          icon="success"
          title="Create Profile Successful"
          sub-title="Click 'Review' to open the profile details."
        >
          <template #extra>
            <el-button type="success" @click="OnReview">Review</el-button>
            <el-button type="primary" @click="OnClose">Back</el-button>
          </template>
        </el-result>
        </div>
      </ElCol>
      </ElRow>

      <div id="save-banner" v-if="step !=CreateProfleStep.STEP_TYPE && step != CreateProfleStep.STEP_DONE">
        <ElButton type="primary" size="large" @click="PrevStep" v-if="step != CreateProfleStep.STEP_META"  >Prev</ElButton>
        <ElButton type="primary" size="large" @click="NextStep" v-if="step != CreateProfleStep.STEP_DONE" >Next</ElButton>
      </div>
    </ElCol>
  </ElRow>
</template>
<script setup lang="ts">

import CodeEditor from '@/components/CodeEditor.vue'
import DataPointEditor from '@/components/DataPointEditor.vue'
import ProfileMetaForm from '@/components/ProfileMetaForm.vue'
import ProfileLoRaWANForm from '@/components/ProfileLoRaWANForm.vue'
import CodecValidator from '@/components/CodecValidator.vue'
import CreateMethod from './CreateMethod.vue'
import { computed, onMounted, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { NewProfileStore } from '@/store/newProfileStore'
import { useRoute, useRouter } from 'vue-router'
import { ProfilesStore } from '@/store/profiles'

const router = useRouter()

interface CreateProfleStepMap {
  STEP_TYPE: 0
  STEP_META: 1,
  STEP_DP: 2,
  STEP_VERIFY: 3,
  STEP_DONE: 4,
}

const CreateProfleStep:CreateProfleStepMap = {
  STEP_TYPE: 0,
  STEP_META: 1,
  STEP_DP: 2,
  STEP_VERIFY: 3,
  STEP_DONE: 4
}

const route = useRoute()
const editId = route.query.id ? route.query.id as string : undefined
const step = ref<CreateProfleStepMap[keyof CreateProfleStepMap]>(CreateProfleStep.STEP_TYPE)
const newProfileStore = new NewProfileStore()
const profileOverview = computed(() => newProfileStore.GetProfile())
const lorawanRef = ref()
const metaRef = ref()
const DataPointEditorRef = ref()
const CodeCEditorRef = ref()
const CodeValidatorRef = ref()
const profileStore = new ProfilesStore()
let isEdit = false
const NextStep = async () => {
  switch (step.value) {
    case CreateProfleStep.STEP_META: {
      try {
        await metaRef.value.Submit()
        await lorawanRef.value.Submit()
        step.value += 1
      } catch (err: any) {
        ElMessage.error(err.message)
      }
      break
    }
    case CreateProfleStep.STEP_DP: {
      try {
        await CodeCEditorRef.value.Submit()
        step.value += 1
      } catch (err: any) {
        ElMessage.error(err.message)
      }
      break
    }
    case CreateProfleStep.STEP_VERIFY: {
      try {
        await CodeValidatorRef.value.Submit()
        const data = newProfileStore.GetProfile()
        if (isEdit) {
          loading.value = true
          profileStore.Update(editId!, data).then(() => {
            ElMessage.success('Update Successful')
            step.value += 1
          }).catch((err:unknown) => {
            ElMessage.error('Unable to update profile' + err.message)
          }).finally(() => {
            loading.value = false
          })
        } else {
          loading.value = true
          profileStore.Create(data).then(() => {
            ElMessage.success('Create Successful')
            step.value += 1
          }).catch(err => {
            ElMessage.error(err.message)
          }).finally(() => {
            loading.value = false
          })
        }
      } catch (err: any) {
        ElMessage.error(err.message)
      }

      break
    }
    default: step.value += 1; break
  }
}

const PrevStep = () => {
  if (step.value > CreateProfleStep.STEP_META) { step.value -= 1 }
}

const OnClose = () => {
  router.back()
}

const OnReview = () => {
  const id = newProfileStore.GetProfile().id
  router.push({ name: 'profile-overview', query: { id: id } })
}

const loading = ref(false)

onMounted(async() => {
  if (route.query.id !== undefined) {
    isEdit = true
    step.value = 1
    try {
      loading.value = true
      const data = await profileStore.Fetch(route.query.id as string)
      await newProfileStore.Upload(data)
      metaRef.value.Load()
      lorawanRef.value.Load()
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  }
})

</script>
<style lang="scss" scoped >

#save-banner {

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 180px;

}
</style>
