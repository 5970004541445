import { Commit, useStore } from 'vuex'
import { DataPoint, LoRaWANProfile, Profile, ProfileContent, ProfileMeta } from './profile.interface'
import api from '@/api/api'
import { Store } from 'element-plus/es/components/table/src/store'

interface OptionMap {
 GETTER_PROFILE: 'newProfileStore/profile',
 GETTER_DATAPOINT: 'newProfileStore/dataPoints',
 GETTER_META: 'newProfileStore/meta',
 GETTER_CODEC: 'newProfileStore/codec',
 ACTION_PUSH_DATAPOINT: 'newProfileStore/PushDataPoint'
 ACTION_RM_DATAPOINT: 'newProfileStore/DeleteDataPoint'
 ACTION_SUBMIT_META: 'newProfileStore/SubmitMeta',
 ACTION_SUBMIT_CODEC: 'newProfileStore/SubmitCodec',
 ACTION_SUBMIT: 'newProfileStore/Submit'
}

const Option: OptionMap = {
  GETTER_DATAPOINT: 'newProfileStore/dataPoints',
  GETTER_META: 'newProfileStore/meta',
  ACTION_PUSH_DATAPOINT: 'newProfileStore/PushDataPoint',
  ACTION_SUBMIT_META: 'newProfileStore/SubmitMeta',
  ACTION_SUBMIT_CODEC: 'newProfileStore/SubmitCodec',
  GETTER_CODEC: 'newProfileStore/codec',
  ACTION_SUBMIT: 'newProfileStore/Submit',
  GETTER_PROFILE: 'newProfileStore/profile',
  ACTION_RM_DATAPOINT: 'newProfileStore/DeleteDataPoint'
}

interface StoreState {
  data: ProfileContent
}
const state: StoreState = {
  data: <ProfileContent>{
    model: undefined,
    lorawan: {
      region: 'EU433',
      macVersion: 'LORAWAN_1_0_0',
      regionalParametetsRevision: 'B',
      adrAlgorithm: 'LoRA Only',
      supportClassB: false,
      supportClassC: false,
      supportOTAA: false,
      classCDownlinkTimeout: 0
    },
    datatype: {},
    vendor: '',
    docsUrl: [],
    codec: '',
    profileVersion: '1.0.0'
  }
}

const mutations = {
  SubmitMeta(state: StoreState, data: ProfileMeta) {
    state.data.model = data.model
    state.data.vendor = data.vendor
    state.data.description = data.description
    state.data.imageUrl = data.image
    state.data.docsUrl = data.docs?.map(file => { return file })
  },

  PushDataPoint(state: StoreState, data: DataPoint) {
    state.data.datatype[data.channel!] = data
  },
  DeleteDataPoint(state: StoreState, channel: number) {
    delete state.data.datatype[channel]
  },

  SubmitCodec(state: StoreState, codec: string) {
    state.data.codec = codec
  },

  Upload(state: StoreState, data: ProfileContent) {
    state.data = data
  },

  New(state: StoreState) {
    state.data = <ProfileContent>{
      lorawan: {
        region: 'EU433',
        macVersion: 'LORAWAN_1_0_0',
        regionalParametetsRevision: 'B',
        adrAlgorithm: 'LoRA Only',
        supportClassB: false,
        supportClassC: false,
        supportOTAA: false,
        classCDownlinkTimeout: 0
      },
      datatype: {},
      docsUrl: [],
      codec: '',
      profileVersion: '1.0.0'
    }
  },
  Fetch(state: StoreState, data: ProfileContent) {
    state.data = data
  }
}

const actions = {
  SubmitMeta({ commit }: {commit: Commit}, data: ProfileMeta) {
    commit('SubmitMeta', data)
  },
  PushDataPoint({ commit }: { commit: Commit }, data: DataPoint) {
    commit('PushDataPoint', data)
  },

  DeleteDataPoint({ commit }: { commit: Commit}, data: number) {
    commit('DeleteDataPoint', data)
  },

  SubmitCodec({ commit }: { commit: Commit }, codec: string) {
    commit('SubmitCodec', codec)
  },

  Upload({ commit } : { commit: Commit}, data:Profile) {
    commit('Upload', data)
  },
  New({ commit }: { commit: Commit}) {
    commit('New')
  },

  Edit({ commit }:{commit: Commit}, id: string) {
    return new Promise<void>((resolve, reject) => {
      api.get('/profile/' + id).then(res => {
        commit('Fetch', res.data.data)
        resolve()
      }).catch(err => {
        reject(new Error(err.response?.data?.errorMesssage))
      })
    })
  }
}

const getters = {

  profile: (state: StoreState) => () => {
    return state.data
  },

  dataPoints: (state: StoreState) => () => {
    const datapoints = Object.entries(state.data.datatype).map(item => {
      item[1].channel = parseInt(item[0])
      return item[1]
    })
    return datapoints
  },

  meta: (state: StoreState) => (): ProfileMeta => {
    const meta: ProfileMeta = {
      model: state.data.model,
      vendor: state.data.vendor,
      description: state.data.description,
      image: state.data.imageUrl,
      docs: state.data.docsUrl || []
    }

    return meta
  },

  lorawan: (state: StoreState) => () : LoRaWANProfile => {
    return state.data.lorawan
  },

  codec: (state: StoreState) => (): string | undefined => {
    return state.data.codec
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export class NewProfileStore {
  private store

  constructor() {
    this.store = useStore()
  }

  DataPoints = ():DataPoint[] => {
    return this.store.getters[Option.GETTER_DATAPOINT]()
  }

  PushDataPoint = (item: DataPoint) => {
    return this.store.dispatch(Option.ACTION_PUSH_DATAPOINT, item)
  }

  DeleteDataPoint = (channel: number) => {
    return this.store.dispatch(Option.ACTION_RM_DATAPOINT, channel)
  }

  SubmitMeta = (data: ProfileMeta) => {
    return this.store.dispatch(Option.ACTION_SUBMIT_META, data)
  }

  GetNextChannelID = (): number | undefined => {
    const datatype = this.DataPoints()
    for (let i = 1; i < 255; i++) {
      let inuse = false
      for (const idx in datatype) {
        if (datatype[idx].channel === i) {
          inuse = true
          break
        }
      }
      if (!inuse) {
        return i
      }
    }
  }

  GetProfileMeta = (): ProfileMeta => {
    return this.store.getters[Option.GETTER_META]()
  }

  GetLoRaWAN = (): LoRaWANProfile => {
    return this.store.getters['newProfileStore/lorawan']()
  }

  SubmitCodec = (codec: string) => {
    return this.store.dispatch(Option.ACTION_SUBMIT_CODEC, codec)
  }

  GetCodec = (): string => {
    return this.store.getters[Option.GETTER_CODEC]()
  }

  GetProfile = () => {
    return this.store.getters[Option.GETTER_PROFILE]()
  }

  New = () => {
    return this.store.dispatch('newProfileStore/New')
  }

  Edit = (id: string) => {
    return this.store.dispatch('newProfileStore/Edit', id)
  }

  Upload = (data: Profile) => {
    return this.store.dispatch('newProfileStore/Upload', data.profile)
  }
}
