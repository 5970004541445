<template>
  <BlockTitle title="Decode/Encode Script" description="Edit JavaScript scripts to extract datapoint values from LoRaWAN raw data or convert datapoint values into LoRaWAN raw Data." />
  <ElRow>
    <ElCol :span="24">
      <ElRow justify="end">
        <ElIcon @click="OnFullScreen" style="margin-bottom:12px">
          <FullScreen  />
        </ElIcon>
    </ElRow>
  <div id="editor" ref="monacoEditorContainer" style="width:900px;min-height: 400px;"></div>
</ElCol>
</ElRow>
  <ElDialog v-model="fullScreen" :fullscreen="true" class="fullscreen-dialog" :show-close="false">
    <template #header>
      <ElRow justify="end">
        <ElIcon :size="16" @click="OnExitFullscreen">
          <FullScreen />
        </ElIcon>
      </ElRow>
    </template>
  <template #default>
    <div style="display:flex;height:95%;width: 100%;flex-direction: column;">
      <div id="fullScreenEiditor" ref="fullscreenEditorDiv"></div>
      <ElRow justify="center" style="margin-top:12px">
        <ElButton @click="OnExitFullscreen" type="primary">Close</ElButton>
      </ElRow>
    </div>
  </template>
</ElDialog>
</template>
<script setup lang="ts">
import { NewProfileStore } from '@/store/newProfileStore'
import BlockTitle from './BlockTitle.vue'
import { Plus, Delete, FullScreen } from '@element-plus/icons-vue'
import * as monaco from 'monaco-editor'

import { onMounted, ref, defineExpose, nextTick } from 'vue'

const profileStore = new NewProfileStore()
const fullScreen = ref(false)
const BACNET_CODEC = `
function Decode(fPort, data, variables) {
    /* Add your decode code here */
    /* 
     * input:
     *   fPort: The fPort of the LoRaWAN message
     *   data: The raw data array of the LoRaWAN message payload
     *   variables: variables of the device
     * return: one or more decoded data item: 
     *   {
     *      channel: number,
     *      value: number
     *   }
     *   Eg. 
     *   return [{ channel: 1, value: 12.5 },{ channel: 2,value: 38}]
     */
    /****** START OF DECODE ******/
    return []
    /****** END OF DECODE *******/
}

function Encode(data, variables) {
     /* Add your encode code here */
     /* input:
      *   data: the data unit include the channel id & desired value :
      *   {
      *     channel: number,
      *     value: number
      *   }
      *   variables: other data units of this device with latest value:
      *     [{ channel: number, value: number } ...]
      *     
    /****** START OF ENCODE ******/

    /****** END OF ENCOD ********/
}


function decodeUplink(input) {
    return {
        data: Decode(input.fPort, input.bytes, input.variables)
    }
}

function encodeDownlink(input) {
    return {
        bytes: Encode(input.data, input.variables)
    }
}`

const monacoEditorContainer = ref()
const fullscreenEditorDiv = ref()
let editorInstance:any
let fullscreenEditorInstance: any

const editor = () => {
  const codec = profileStore.GetCodec()
  if (monacoEditorContainer.value) {
    editorInstance = monaco.editor.create(monacoEditorContainer.value, {
      theme: 'vs-light',
      value: codec || BACNET_CODEC,
      language: 'javascript',
      folding: true,
      foldingHighlight: true,
      foldingStrategy: 'indentation',
      showFoldingControls: 'always',
      disableLayerHinting: true,
      emptySelectionClipboard: false,
      selectionClipboard: false,
      automaticLayout: true,
      codeLens: false,
      fontSize: 14,
      scrollBeyondLastLine: false,
      colorDecorators: true,
      accessibilitySupport: 'off',
      lineNumbers: 'on',
      lineNumbersMinChars: 5,
      readOnly: false
    })
  }
}

const Submit = async () => {
  return new Promise<void>((resolve, reject) => {
    profileStore.SubmitCodec(editorInstance.getValue()).then(() => {
      resolve()
    }).catch(err => {
      reject(err)
    })
  })
}

const OnExitFullscreen = () => {
  editorInstance.setValue(fullscreenEditorInstance.getValue())
  fullScreen.value = false
}
const OnFullScreen = () => {
  fullScreen.value = true
  if (fullscreenEditorInstance) {
    fullscreenEditorInstance.setValue(editorInstance.getValue())
  } else {
    nextTick(() => {
      fullscreenEditorInstance = monaco.editor.create(fullscreenEditorDiv.value, {
        theme: 'vs-light',
        value: editorInstance.getValue(),
        language: 'javascript',
        folding: true,
        foldingHighlight: true,
        foldingStrategy: 'indentation',
        showFoldingControls: 'always',
        disableLayerHinting: true,
        emptySelectionClipboard: false,
        selectionClipboard: false,
        automaticLayout: true,
        codeLens: false,
        fontSize: 14,
        scrollBeyondLastLine: false,
        colorDecorators: true,
        accessibilitySupport: 'off',
        lineNumbers: 'on',
        lineNumbersMinChars: 5,
        readOnly: false
      })
    })
  }
}

defineExpose({ Submit })

onMounted(() => {
  editor()
})
</script>
<style lang="scss" >
#editor {

  padding: 12px;
  background-color: #FFF;
  border: 1px solid var(--el-border-color);
  border-radius: var(--el-border-radius-base);
}

#fullScreenEiditor {
  flex: 1
}

.fullscreen-dialog.el-dialog {
  border-radius: 0;
}

.fullscreen-dialog  .el-dialog__body {
  padding: 0;
  height: 100% !important;
}
</style>
