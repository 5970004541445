<template>
  <div class="login-container">
    <div class="form"  >
      <ElText class="title">Manage BACnet Device Profiles For your BACnet Gateway</ElText>
      <div style="display:flex; flex-direction:  column;">
        <ElButton id="login" type="primary" size="large" @click="Login">
          Sign in with RAK ID
        </ElButton>
        <ElText style="width:300px;margin-top: 40px;" size="large" >As WisDM is a part of RAK ecosystem you will create a single account, RAK ID. Find out more about RAK ID.</ElText>
      </div>
      <div></div>
    </div>
    <div  class="left">
    </div>
  </div>
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router'
import { ElText } from 'element-plus'
const router = useRouter()
const Login = () => {
  router.push({ name: 'signin' })
}
</script>
<style lang="scss" scoped>
.login-container {
  display: flex;
  flex: 1;
}
.left {
  background-color: var(--el-color-primary);
  flex: 4
}
.form {
  display: flex;
  flex-direction: column;
  flex: 3;
  min-width: 600px;
  justify-content: space-around;
  align-items: center;

}
#login {
  height: 56px;
  border-radius: 28px;
  padding: 0px 80px;
}

.title {
  font-size: 32px;
  width: 400px;
  margin-bottom: 120px;
  color: #000;
}
</style>
