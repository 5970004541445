import { Commit, useStore } from 'vuex'
import { Profile, ProfileContent } from './profile.interface'
import api from '@/api/api'
import { useStops } from 'element-plus/es/components/slider/src/composables'
import yaml from 'yaml'
import { saveAs } from 'file-saver'

interface ProfilesState {
  data: Profile[]
}
const state: { data: Profile[]} = { data: [] }

const mutations = {
  fetch(state: ProfilesState, data: Profile[]) {
    state.data = data
  },
  append(state: ProfilesState, data: Profile) {
    state.data.push(data)
  },
  delete(state: ProfilesState, id: string) {
    let idx
    for (let i = 0; i < state.data.length; i++) {
      const item = state.data[i]
      if (item.id === id) {
        idx = i
        break
      }
    }
    if (idx !== undefined) {
      state.data.splice(idx, 1)
    }
  },
  update(state: ProfilesState, data:Profile) {
    let idx
    for (let i = 0; i < state.data.length; i++) {
      const item = state.data[i]
      if (item.id === data.id) {
        idx = i
        break
      }
    }

    if (idx !== undefined) {
      state.data[idx] = data
    } else {
      state.data.push(data)
    }
  }
}

const actions = {
  create({ commit } : { commit : Commit}, data: Profile) {
    return new Promise<void>((resolve, reject) => {
      api.put('/profile', data).then(res => {
        const id = res.data.data
        data.id = id
        // commit('append', data)
        resolve()
      }).catch(error => {
        reject(new Error(error.response?.data.message))
      })
    })
  },
  update({ commit }:{commit: Commit}, { id, data }:{ id: string, data: ProfileContent}) {
    return new Promise<void>((resolve, reject) => {
      console.log(id, data)
      api.post(`/profile/${id}`, data).then(res => {
        // console.log(res.data)
        resolve()
      }).catch(error => {
        reject(new Error(error.response?.data))
      })
    })
  },
  fetchAll({ commit }: { commit: Commit }) {
    return new Promise<void>((resolve, reject) => {
      api.get('/profile').then(res => {
        const profiles = <Profile[]>res.data.data
        commit('fetch', profiles)
        resolve()
      }).catch(err => {
        console.log(err.response.data)
        reject(new Error(err.response?.data.message))
      })
    })
  },

  fetch({ commit }: { commit: Commit }, id: string) {
    return new Promise<Profile>((resolve, reject) => {
      api.get('/profile/' + id).then(res => {
        const profile = res.data.data as Profile
        commit('update', profile)
        resolve(profile)
      }).catch(err => {
        reject(new Error(err.response?.data.message))
      })
    })
  },
  delete({ commit }:{commit: Commit}, id: string) {
    return new Promise<void>((resolve, reject) => {
      api.delete('/profile/' + id).then(res => {
        commit('delete', id)
        resolve()
      }).catch(err => {
        reject(new Error(err.response?.data.message))
      })
    })
  }
}

const getters = {

  profiles: (state: ProfilesState) => () => {
    return state.data
  },

  profile: (state: ProfilesState) => (id: string) => {
    console.log(`try to get profile ${id}`)
    for (let i = 0; i < state.data.length; i++) {
      if (state.data[i].id === id) {
        return state.data[i]
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export class ProfilesStore {
  private store
  constructor() {
    this.store = useStore()
  }

  GetProfiles() {
    return this.store.getters['profiles/profiles']()
  }

  GetProfile(id: string) {
    return this.store.getters['profiles/profile'](id)
  }

  Fetch(id: string) {
    return this.store.dispatch('profiles/fetch', id)
  }

  FetchAll() {
    return this.store.dispatch('profiles/fetchAll')
  }

  Create(data: Profile) {
    return this.store.dispatch('profiles/create', data)
  }

  Update(id: string, data: ProfileContent) {
    console.log(id, data)
    return this.store.dispatch('profiles/update', { id, data })
  }

  Download(id: string) {
    const profile = this.GetProfile(id)
    const data = yaml.stringify(profile)

    const blob = new Blob([data], { type: 'application/yml' })

    saveAs(blob, profile.profile.model + '.bacprof')
  }

  Delete(id: string) {
    return this.store.dispatch('profiles/delete', id)
  }
}
