import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import NewProfile from '@/views/NewProfile.vue'
import LoginView from '@/views/LoginView.vue'
import MainView from '@/views/MainView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  }, {
    path: '/signin',
    name: 'signin',
    component: () => import('@/views/SignIn.vue')
  }, {
    path: '/profiles',
    name: 'profiles',
    component: MainView,
    meta: { requiresAuth: true },
    children: [{
      path: '/profiles',
      name: 'profiles',
      component: () => import('@/views/ProfileList.vue')
    }, {
      path: '/profiles/edit',
      name: 'edit',
      component: NewProfile
    }, {
      path: '/profiles/overview',
      name: 'profile-overview',
      component: () => import('@/views/ProfileOverview.vue')
    }, {
      path: '/browser',
      name: 'browser',
      component: () => import('@/views/ProfileBrowserView.vue')
    }]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next({ name: 'signin' })
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
