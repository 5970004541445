<template>
  <ElRow style="margin-top:20px;padding:0px 40px">
    <ElCol :span="12" :offset="12" style="text-align: right;">

      <el-dropdown ref="dropdown1" trigger="click" style="margin-right: 30px">
        <ElAvatar :size="50" :src="circleUrl" @click="OnMenu"/>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click="logout">Logout</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    </ElCol>
  </ElRow>
</template>
<script lang="ts" setup >
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import type { DropdownInstance } from 'element-plus'

const router = useRouter()
const dropdown1 = ref<DropdownInstance>()
const OnMenu = () => {
  if (!dropdown1.value) return
  dropdown1.value.handleOpen()
}
const logout = () => {
  localStorage.removeItem('token')
  router.push({ name: 'login' })
}
</script>
