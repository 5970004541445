<template>
   <ElRow>
      <ElText class="section-title">Uplink Encoder Validator</ElText>
  </ElRow>
  <ElRow>
    <ElCol :span="10">
      <ElRow style="margin-bottom: 12px">
        <ElCol :span=2></ElCol>
        <ElCol :span=4>
          <ElText >fPort</ElText>
        </ElCol>
        <ElCol :span=11 :offset=1 >
          <ElText>Uplink RAW Data</ElText>
        </ElCol>
        <ElCol :span=2></ElCol>
      </ElRow>

      <ElRow v-for="(input, idx) in UplinkTestInput.uplinkInput" :key="idx" style="margin-bottom: 12px">
        <ElCol :span=2><ElText>{{ idx + 1 }}</ElText></ElCol>
        <ElCol :span=4  >
            <ElInput v-model="input.fport"  controls-position="right"/>
        </ElCol>
        <ElCol :span=11 :offset=1 >
            <ElInput v-model="input.rawData" @input="formatHex(input)" />
        </ElCol>
        <ElCol :span=2 style="display: flex; justify-content: center;align-items: center">
          <ElIcon v-if="!(idx == 0 && UplinkTestInput.uplinkInput.length == 1)" @click="UplinkTestInput.uplinkInput.splice(UplinkTestInput.uplinkInput.indexOf(input), 1)">
            <Delete />
          </ElIcon>
        </ElCol>
      </ElRow>

      <ElRow justify="center">
        <ElButton type="default" :icon="Plus" @click="OnAddNewUplinkItem" >Add new Data</ElButton>
        <ElButton type="primary" @click="OnUplinkTest">Test</ElButton>
      </ElRow>
    </ElCol>
    <ElCol :span="14" >
      <ElRow>
        <ElTable :data="UplinkStatusTable" stripe table-layout="auto">
          <ElTableColumn label="Channel" prop="dataPoint.channel"/>
          <ElTableColumn label="Name" prop="dataPoint.name"/>
          <ElTableColumn label="Object Type" prop="dataPoint.type" />
          <ElTableColumn label="Value" prop="value"/>
          <ElTableColumn label="Status" >
            <template #default="scope">

              <ElTag type="info" size="small" v-if='scope.row.isValid == ValidateState.UNCONFIRMED' >Untested</ElTag>
              <ElTag type="success" size="small" v-if="scope.row.isValid == ValidateState.TRUE">Pass</ElTag>
              <ElTag type="error" size="small" v-if="scope.row.isValid == ValidateState.FALSE">Failed</ElTag>
            </template>
          </ElTableColumn>
        </ElTable>
      </ElRow>
    </ElCol>
  </ElRow>
  <ElDialog v-model='showTestResult' class="test-result-dialog">
  <template #default>
    <ElRow>
      <ElText>Debug Output</ElText>
    </ElRow>
    <ElRow>
      <ElInput type="textarea" style="width:100%" :rows="6" v-model="inputTestConsole" />
    </ElRow>
    <ElRow>
    <ElTable :data="UplinkResultTable" stripe table-layout="auto">
      <ElTableColumn label="Channel" prop="dataPoint.channel"/>
      <ElTableColumn label="Name" prop="dataPoint.name"/>
      <ElTableColumn label="Object Type" prop="dataPoint.type" />
      <ElTableColumn label="Output Value" prop="value"/>
      <ElTableColumn label="Is Correct" >
        <template #default="scope">
          <ElTag type="warning" size="small" v-if="scope.row.isValid == ValidateState.UNCONFIRMED" @click="scope.row.isValid = ValidateState.TRUE" >Yes</ElTag>
          <ElTag type="warning" size="small" v-if="scope.row.isValid == ValidateState.UNCONFIRMED" @click="scope.row.isValid = ValidateState.FALSE">No</ElTag>
          <ElTag type="success" size="small" v-if="scope.row.isValid == ValidateState.TRUE">Pass</ElTag>
          <ElTag type="error" size="small" v-if="scope.row.isValid == ValidateState.FALSE">Failed</ElTag>
        </template>
      </ElTableColumn>
    </ElTable>
  </ElRow >
  <ElRow>
    <ElButton @click="CloseUplinkResultDialog" type="primary" >Done</ElButton>
  </ElRow>
  </template>
  </ElDialog>
  <ElRow>
      <ElText class="section-title">Downlink Decoder Validator</ElText>
  </ElRow>
  <ElRow v-if="DownlinkTestInputForm.length === 0" justify="center" >
    <ElEmpty />
  </ElRow>
  <ElRow v-else>
    <ElCol :span="10">
      <ElRow style="margin-bottom: 12px">
        <ElCol :span="4"><ElText></ElText></ElCol>
        <ElCol :span="8"><ElText>Channel</ElText></ElCol>
        <ElCol :span="12"><ElText>Set Value</ElText></ElCol>
      </ElRow>
      <ElRow v-for="downlink in DownlinkTestInputForm" :key="downlink.channel" style="margin-bottom: 12px">
        <ElCol :span="4"><ElText></ElText></ElCol>
        <ElCol :span="8">
          <ElInputNumber v-model="downlink.channel" />
        </ElCol>
        <ElCol :span="12">
          <ElInputNumber v-model="downlink.value" />
        </ElCol>
      </ElRow>
      <ElRow>
        <ElCol :span=20 :offset="12" >
          <ElButton @click="OnDownlinkTest" type="primary">Test</ElButton>
        </ElCol>
      </ElRow>
    </ElCol>
    <ElCol :span="14">
      <ElTable  :data="DownlinkStatusTable" stripe table-layout="auto">
        <ElTableColumn label="Channel" prop="dataPoint.channel"/>
        <ElTableColumn label="Name" prop="dataPoint.name"/>
        <ElTableColumn label="Object Type" prop="dataPoint.type" />
        <ElTableColumn label="Downlink RAW Data" prop="value"/>
        <ElTableColumn label="Is Correct" >
          <template #default="scope">
            <ElTag type="info" size="small" v-if='scope.row.isValid == ValidateState.UNCONFIRMED' >Untested</ElTag>
            <ElTag type="success" size="small" v-if="scope.row.isValid == ValidateState.TRUE">Pass</ElTag>
            <ElTag type="error" size="small" v-if="scope.row.isValid == ValidateState.FALSE">Failed</ElTag>
          </template>
        </ElTableColumn>
      </ElTable>
    </ElCol>
  </ElRow>
  <ElDialog v-model="showDownlinkTestResult" class="test-result-dialog">
    <template #default>
      <ElRow>
      <ElText>Debug Output</ElText>
    </ElRow>
    <ElRow>
      <ElInput type="textarea" style="width:100%" :rows="6" v-model="downlinkTestConsole" />
    </ElRow>
    <ElRow>
      <ElTable  :data="DownlinkStatusTable" stripe table-layout="auto">
        <ElTableColumn label="Channel" prop="dataPoint.channel"/>
        <ElTableColumn label="Name" prop="dataPoint.name"/>
        <ElTableColumn label="Object Type" prop="dataPoint.type" />
        <ElTableColumn label="Downlink RAW Data" prop="value"/>
        <ElTableColumn label="Is Correct" >
          <template #default="scope">
            <ElTag type="warning" size="small" v-if="scope.row.isValid == ValidateState.UNCONFIRMED" @click="scope.row.isValid = ValidateState.TRUE" >Yes</ElTag>
            <ElTag type="warning" size="small" v-if="scope.row.isValid == ValidateState.UNCONFIRMED" @click="scope.row.isValid = ValidateState.FALSE">No</ElTag>
            <ElTag type="success" size="small" v-if="scope.row.isValid == ValidateState.TRUE">Pass</ElTag>
            <ElTag type="error" size="small" v-if="scope.row.isValid == ValidateState.FALSE">Failed</ElTag>
          </template>
        </ElTableColumn>
      </ElTable>
    </ElRow>
    <ElRow justify="center" >
      <ElButton type="primary" @click="showDownlinkTestResult = false">Done</ElButton>
    </ElRow>
    </template>
  </ElDialog>
</template>
<script setup lang="ts">
import { DataPointItem } from '@/store/profile.interface'
import { ElEmpty, ElInputNumber, ElMessageBox, ElTableColumn } from 'element-plus'
import { reactive, ref, defineExpose } from 'vue'
import { NewProfileStore } from '@/store/newProfileStore'
import { Plus, Delete } from '@element-plus/icons-vue'
import { IsBACnetObjectWritable } from '@/store/bactype'
const inputTestConsole = ref('')
const profileStore = new NewProfileStore()

interface ValidStateMap {
  FALSE: 0
  TRUE: 1
  UNCONFIRMED: 2
}

const ValidateState: ValidStateMap = {
  FALSE: 0,
  TRUE: 1,
  UNCONFIRMED: 2
}
interface DataPointTestResultItem {
  dataPoint: DataPointItem,
  value: number | string | undefined,
  isValid: ValidStateMap[keyof ValidStateMap]
}

interface UplinkTestInputItem {
  fport: number,
  rawData: string
}
interface UplinkInputForm {
  uplinkInput: UplinkTestInputItem[]
}

const formatHex = (input: UplinkTestInputItem) => {
  const cleanInput = input.rawData.replace(/[^0-9a-fA-F]/g, '')
  const formatted = cleanInput.match(/.{1,2}/g)?.join(' ') || ''
  input.rawData = formatted.toUpperCase()
}

const UplinkStatusTable = ref<DataPointTestResultItem[]>(profileStore.DataPoints().map(dp => ({ dataPoint: dp, value: undefined, isValid: ValidateState.UNCONFIRMED })))

const UplinkTestInput = reactive<UplinkInputForm>({ uplinkInput: [{ fport: 1, rawData: '' }] })

const OnAddNewUplinkItem = () => {
  UplinkTestInput.uplinkInput.push({ fport: 1, rawData: '' })
}

function hexStringToArray(hexString: string): number[] {
  const result: number[] = []

  for (let i = 0; i < hexString.length; i += 2) {
    const byte = parseInt(hexString.substr(i, 2), 16)
    result.push(byte)
  }

  return result
}

const showTestResult = ref<boolean>(false)

const UplinkResultTable = ref<DataPointTestResultItem[]>([])
const OnUplinkTest = async () => {
  inputTestConsole.value = ''
  UplinkResultTable.value = []
  const AppendUplinkResultToTable = (result:any) => {
    const datapoints = profileStore.DataPoints()
    if (Array.isArray(result.data)) {
      for (let i = 0; i < result.data.length; i++) {
        const item = result.data[i]
        console.log(item)
        if (item.channel) {
          datapoints.forEach(dp => {
            console.log(dp)
            if (dp.channel === item.channel) {
              UplinkResultTable.value.push({
                dataPoint: dp,
                value: item.value,
                isValid: 2
              })
            }
          })
        }
      }
    }
  }

  const ExecInputTestItem = (fport: number, data: string, script: any) => {
    try {
      console.log(`#### ${data} ###`)
      const result = script.decodeUplink({
        fPort: fport,
        bytes: hexStringToArray(data),
        variables: {}
      })

      console.log(`### ${JSON.stringify(result)} ###`)
      showTestResult.value = true
      AppendUplinkResultToTable(result)

      inputTestConsole.value += JSON.stringify(result, null, 4)
    } catch (error:any) {
      console.log(error.message)
    }
  }
  const codec = profileStore.GetCodec()
  // eslint-disable-next-line no-new-func
  const func = new Function(codec + 'return {decodeUplink, encodeDownlink};')
  // eslint-disable-next-line @typescript-eslint/ban-types
  const script:{ decodeUplink: Function, encodeDownlink: Function} = func()

  UplinkTestInput.uplinkInput.forEach(item => {
    console.log(`try to test fport ${item.fport} ${item.rawData}`)
    ExecInputTestItem(parseInt(item.fport), item.rawData.replace(/\s/g, ''), script)
  })
}

const CloseUplinkResultDialog = () => {
  UplinkResultTable.value.forEach(resultItem => {
    UplinkStatusTable.value.forEach(stateItem => {
      if (resultItem.dataPoint.channel === stateItem.dataPoint.channel) {
        stateItem.isValid = resultItem.isValid
      }
    })
  })
  showTestResult.value = false
}

const DownlinkStatusTable = ref<DataPointTestResultItem[]>(profileStore.DataPoints().filter(item => { return IsBACnetObjectWritable(item.type) }).map(item => ({
  dataPoint: item,
  isValid: ValidateState.UNCONFIRMED,
  value: undefined
})))

interface DownlinkTestInputItem {
  channel: number,
  value: number | undefined
}
const showDownlinkTestResult = ref(false)
const downlinkTestConsole = ref('')

const DownlinkTestInputForm = reactive<DownlinkTestInputItem[]>(profileStore.DataPoints().filter(item => { return IsBACnetObjectWritable(item.type) }).map(item => ({ channel: item.channel!, value: undefined })))
const Submit = async () => {
  return new Promise<void>((resolve, reject) => {
    let invaliditems = 0
    for (let i = 0; i < UplinkStatusTable.value.length; i++) {
      if (UplinkStatusTable.value[i].isValid !== ValidateState.TRUE) {
        invaliditems += 1
      }
    }

    for (let i = 0; i < DownlinkStatusTable.value.length; i++) {
      if (DownlinkStatusTable.value[i].isValid !== ValidateState.TRUE) {
        invaliditems += 1
      }
    }

    if (invaliditems > 0) {
      ElMessageBox.confirm('Here are still some items with failing tests. Do you want to proceed with the submission before completing the tests?', 'Warning', {
        type: 'warning'
      }).then(yes => {
        resolve()
      }).catch(no => {
        reject(new Error('Cancelled due to the unpassed item'))
      })
    } else {
      resolve()
    }
  })
}

const OnDownlinkTest = () => {
  const ExecDownlinkTestItem = (channel: number, value: number, others: DownlinkTestInputItem[], script: any) => {
    console.log(channel, value, others)
    try {
      const result = script.encodeDownlink({
        data: {
          channel: channel,
          value: value
        },
        variables: others
      })

      console.log(result)
      DownlinkStatusTable.value.forEach(item => {
        if (item.dataPoint.channel === channel) {
          item.value = result.bytes.map(byte => (byte.toString(16).padStart(2, '0'))).join(' ')
        }
      })
      showDownlinkTestResult.value = true
      downlinkTestConsole.value += `>> Test Output for channel ${channel}\n`
      downlinkTestConsole.value += JSON.stringify(result)
      downlinkTestConsole.value += '\n\n'
    } catch (error:any) {
      console.log(error.message)
    }
  }

  const codec = profileStore.GetCodec()
  // eslint-disable-next-line no-new-func
  const func = new Function(codec + 'return {decodeUplink, encodeDownlink};')
  // eslint-disable-next-line @typescript-eslint/ban-types
  const script:{ decodeUplink: Function, encodeDownlink: Function} = func()

  DownlinkTestInputForm.forEach(element => {
    if (element.value !== undefined) { ExecDownlinkTestItem(element.channel, element.value!, DownlinkTestInputForm, script) }
  })
}
defineExpose({ Submit })

</script>
<style lang="scss" scoped>
.test-result-dialog .el-row {
  padding: 10px 20px
}
.el-tag {
  cursor: pointer;
  margin-right: 2px;
}
</style>
