export interface BACObjectTypeMap{
  /*
  'AccessCredentialObject'
  'AccessDoorObject'
  'AccessPointObject'
  'AccessRightsObject'
  'AccessUserObject'
  'AccessZoneObject'
  'AccumulatorObject'
  'AlertEnrollmentObject'
  */
  ANALOG_INPUT : 'AnalogInputObject'
  ANALOG_OUTPUT : 'AnalogOutputObject'
  ANALOG_VALUE : 'AnalogValueObject'
  /*
  'AuditLogObject'
  'AuditReporterObject'
  'AveragingObject'
  */
  BINARY_INPUT : 'BinaryInputObject'
  // 'BinaryLightingOutputObject'
  BINARY_OUTPUT : 'BinaryOutputObject'
  BINARY_VALUE : 'BinaryValueObject'
  /*
  'BitStringValueObject'
  'CalendarObject'
  'ChannelObject'
  'CharacterStringValueObject'
  'CommandObject'
  'CredentialDataInputObject'
  'DatePatternValueObject'
  'DateValueObject'
  'DateTimePatternValueObject'
  'DateTimeValueObject'
  'DeviceObject'
  'ElevatorGroupObject'
  'EscalatorObject'
  'EventEnrollmentObject'
  'EventLogRecordLogDatum'
  'EventLogRecord'
  'EventLogObject'
  'FileObject'
  'GlobalGroupObject'
  'GroupObject'
  'IntegerValueObject'
  'LargeAnalogValueObject'
  'LifeSafetyPointObject'
  'LifeSafetyZoneObject'
  'LiftObject'
  'LightingOutputObject'
  'LoadControlObject'
  'LoopObject'
  'MultiStateInputObject'
  'MultiStateOutputObject'
  'MultiStateValueObject'
  'NetworkPortObject'
  'NetworkSecurityObject'
  'NotificationClassObject'
  'NotificationForwarderObject'
  */

  OCTET_STRING_VALUE: 'OctetStringValueObject'

  /*
  'PositiveIntegerValueObject'
  'ProgramObject'
  'PulseConverterObject'
  'ScheduleObject'
  'StagingObject'
  'StructuredViewObject'
  'TimePatternValueObject'
  'TimeValueObject'
  'TimerObject'
  'TrendLogObject'
  'TrendLogMultipleObject'
  */
}

export const BACObjectType: BACObjectTypeMap = {
  ANALOG_INPUT: 'AnalogInputObject',
  ANALOG_OUTPUT: 'AnalogOutputObject',
  ANALOG_VALUE: 'AnalogValueObject',
  BINARY_INPUT: 'BinaryInputObject',
  BINARY_OUTPUT: 'BinaryOutputObject',
  BINARY_VALUE: 'BinaryValueObject',
  OCTET_STRING_VALUE: 'OctetStringValueObject'
}

export const IsBACnetObjectWritable = (type: BACObjectTypeMap[keyof BACObjectTypeMap]): boolean => {
  switch (type) {
    case BACObjectType.ANALOG_INPUT:
    case BACObjectType.BINARY_INPUT:return false
    default: return true
  }
}

export interface EngineeringUnitsMap {
  empty: null,
  // Acceleration
  metersPerSecondPerSecond : 'metersPerSecondPerSecond'// 166
  squareMeters : 'squareMeters'// 0
  squareCentimeters : 'squareCentimeters'// 116
  squareFeet : 'squareFeet'// 1
  squareInches : 'squareInches'// 115
  // Currency
  currency1 : 'currency1'// 105
  currency2 : 'currency2'// 106
  currency3 : 'currency3'// 107
  currency4 : 'currency4'// 108
  currency5 : 'currency5'// 109
  currency6 : 'currency6'// 110
  currency7 : 'currency7'// 111
  currency8 : 'currency8'// 112
  currency9 : 'currency9'// 113
  currency10 : 'currency10'// 114
  // Electrical
  milliamperes : 'milliamperes'// 2
  amperes : 'amperes'// 3
  amperesPerMeter : 'amperesPerMeter'// 167
  amperesPerSquareMeter : 'amperesPerSquareMeter'// 168
  ampereSquareMeters : 'ampereSquareMeters'// 169
  decibels : 'decibels'// 199
  decibelsMillivolt : 'decibelsMillivolt'// 200
  decibelsVolt : 'decibelsVolt'// 201
  farads : 'farads'// 170
  henrys : 'henrys'// 171
  ohms : 'ohms'// 4
  ohmMeters : 'ohmMeters'// 172
  ohmMeterPerSquareMeter : 'ohmMeterPerSquareMeter'// 237
  milliohms : 'milliohms'// 145
  kilohms : 'kilohms'// 122
  megohms : 'megohms'// 123
  microSiemens : 'microSiemens'// 190
  millisiemens : 'millisiemens'// 202
  siemens : 'siemens'// 173
  siemensPerMeter : 'siemensPerMeter'// 174
  teslas : 'teslas'// 175
  volts : 'volts'// 5
  millivolts : 'millivolts'// 124
  kilovolts : 'kilovolts'// 6
  megavolts : 'megavolts'// 7
  voltAmperes : 'voltAmperes'// 8
  kilovoltAmperes : 'kilovoltAmperes'// 9
  megavoltAmperes : 'megavoltAmperes'// 10
  ampereSeconds : 'ampereSeconds'// 238
  ampereSquareHours : 'ampereSquareHours'// 246
  voltAmpereHours : 'voltAmpereHours'// 239 //VAh
  kilovoltAmpereHours : 'kilovoltAmpereHours'// 240 //kVAh
  megavoltAmpereHours : 'megavoltAmpereHours'// 241 //MVAh
  voltAmperesReactive : 'voltAmperesReactive'// 11
  kilovoltAmperesReactive : 'kilovoltAmperesReactive'// 12
  megavoltAmperesReactive : 'megavoltAmperesReactive'// 13
  voltAmpereHoursReactive : 'voltAmpereHoursReactive'// 242 //varh
  kilovoltAmpereHoursReactive : 'kilovoltAmpereHoursReactive'// 243 //kvarh
  megavoltAmpereHoursReactive : 'megavoltAmpereHoursReactive'// 244 //Mvarh
  voltsPerDegreeKelvin : 'voltsPerDegreeKelvin'// 176
  voltsPerMeter : 'voltsPerMeter'// 177
  voltsSquareHours : 'voltsSquareHours'// 245
  degreesPhase : 'degreesPhase'// 14
  powerFactor: 'powerFactor'// 15
  webers : 'webers'// 178
  // Energy
  joules : 'joules'// 16
  kilojoules : 'kilojoules'// 17
  kilojoulesPerKilogram : 'kilojoulesPerKilogram'// 125
  megajoules : 'megajoules'// 126
  joulesPerHours : 'joulesPerHours'// 247
  wattHours : 'wattHours'// 18
  kilowattHours : 'kilowattHours'// 19
  megawattHours : 'megawattHours'// 146
  wattHoursReactive : 'wattHoursReactive'// 203
  kilowattHoursReactive : 'kilowattHoursReactive'// 204
  megawattHoursReactive : 'megawattHoursReactive'// 205
  btus : 'btus'// 20
  kiloBtus : 'kiloBtus'// 147
  megaBtus : 'megaBtus'// 148
  therms : 'therms'// 21
  tonHours : 'tonHours'// 22
  // Enthalpy
  joulesPerKilogramDryAir : 'joulesPerKilogramDryAir'// 23
  kilojoulesPerKilogramDryAir : 'kilojoulesPerKilogramDryAir'// 149
  megajoulesPerKilogramDryAir : 'megajoulesPerKilogramDryAir'// 150
  btusPerPoundDryAir : 'btusPerPoundDryAir'// 24
  btusPerPound : 'btusPerPound'// 117
  joulesPerDegreeKelvin : 'joulesPerDegreeKelvin'// 127
  // Entropy
  kilojoulesPerDegreeKelvin : 'kilojoulesPerDegreeKelvin'// 151
  megajoulesPerDegreeKelvin : 'megajoulesPerDegreeKelvin'// 152
  joulesPerKilogramDegreeKelvin : 'joulesPerKilogramDegreeKelvin'// 128
  // Force
  newton : 'newton'// 153
  // Frequency
  cyclesPerHour : 'cyclesPerHour'// 25
  cyclesPerMinute : 'cyclesPerMinute'// 26
  hertz : 'hertz'// 27
  kilohertz : 'kilohertz'// 129
  megahertz : 'megahertz'// 130
  perHour : 'perHour'// 131
  gramsOfWaterPerKilogramDryAir : 'gramsOfWaterPerKilogramDryAir'// 28
  percentRelativeHumidity : 'percentRelativeHumidity'// 29
  micrometers : 'micrometers'// 194
  millimeters : 'millimeters'// 30
  centimeters : 'centimeters'// 118
  kilometers : 'kilometers'// 193
  meters : 'meters'// 31
  inches : 'inches'// 32
  feet : 'feet'// 33
  candelas : 'candelas'// 179
  candelasPerSquareMeter : 'candelasPerSquareMeter'// 180
  wattsPerSquareFoot : 'wattsPerSquareFoot'// 34
  wattsPerSquareMeter : 'wattsPerSquareMeter'// 35
  lumens : 'lumens'// 36
  luxes : 'luxes'// 37
  footCandles : 'footCandles'// 38
  milligrams : 'milligrams'// 196
  grams : 'grams'// 195
  kilograms : 'kilograms'// 39
  poundsMass : 'poundsMass'// 40
  tons : 'tons'// 41
  gramsPerSecond : 'gramsPerSecond'// 154
  gramsPerMinute : 'gramsPerMinute'// 155
  kilogramsPerSecond : 'kilogramsPerSecond'// 42
  kilogramsPerMinute : 'kilogramsPerMinute'// 43
  kilogramsPerHour : 'kilogramsPerHour'// 44
  poundsMassPerSecond : 'poundsMassPerSecond'// 119
  poundsMassPerMinute : 'poundsMassPerMinute'// 45
  poundsMassPerHour : 'poundsMassPerHour'// 46
  tonsPerHour : 'tonsPerHour'// 156
  milliwatts : 'milliwatts'// 132
  watts : 'watts'// 47
  kilowatts : 'kilowatts'// 48
  megawatts : 'megawatts'// 49
  btusPerHour : 'btusPerHour'// 50
  kiloBtusPerHour : 'kiloBtusPerHour'// 157
  horsepower : 'horsepower'// 51
  tonsRefrigeration: 'tonsRefrigeration'// 52
  pascals: 'pascals'// 53
  hectopascals: 'hectopascals'// 133
  kilopascals: 'kilopascals'// 54
  pascalSeconds: 'pascalSeconds'// 253
  millibars: 'millibars'// 134
  bars: 'bars'// 55
  poundsForcePerSquareInch: 'poundsForcePerSquareInch'// 56
  millimetersOfWater: 'millimetersOfWater'// 206
  centimetersOfWater: 'centimetersOfWater'// 57
  inchesOfWater: 'inchesOfWater'// 58
  millimetersOfMercury: 'millimetersOfMercury'// 59
  centimetersOfMercury: 'centimetersOfMercury'// 60
  inchesOfMercury: 'inchesOfMercury'// 61
  degreesCelsius: 'degreesCelsius'// 62
  degreesKelvin: 'degreesKelvin'// 63
  degreesKelvinPerHour: 'degreesKelvinPerHour'// 181
  degreesKelvinPerMinute: 'degreesKelvinPerMinute'// 182
  degreesFahrenheit: 'degreesFahrenheit'// 64
  degreeDaysCelsius: 'degreeDaysCelsius'// 65
  degreeDaysFahrenheit: 'degreeDaysFahrenheit'// 66
  deltaDegreesFahrenheit: 'deltaDegreesFahrenheit'// 120
  deltaDegreesKelvin: 'deltaDegreesKelvin'// 121
  years: 'years'// 67
  months: 'months'// 68
  weeks: 'weeks'// 69
  days: 'days'// 70
  hours: 'hours'// 71
  minutes: 'minutes'// 72
  seconds: 'seconds'// 73
  hundredthsSeconds: 'hundredthsSeconds'// 158
  milliseconds: 'milliseconds'// 159
  newtonMeters: 'newtonMeters'// 160
  millimetersPerSecond: 'millimetersPerSecond'// 161
  millimetersPerMinute: 'millimetersPerMinute'// 162
  metersPerSecond: 'metersPerSecond'// 74
  metersPerMinute: 'metersPerMinute'// 163
  metersPerHour: 'metersPerHour'// 164
  kilometersPerHour: 'kilometersPerHour'// 75
  feetPerSecond: 'feetPerSecond'// 76
  feetPerMinute: 'feetPerMinute'// 77
  milesPerHour: 'milesPerHour'// 78
  cubicFeet: 'cubicFeet'// 79
  cubicFeetPerDay: 'cubicFeetPerDay'// 248
  cubicMeters: 'cubicMeters'// 80
  cubicMetersPerDay: 'cubicMetersPerDay'// 249
  imperialGallons: 'imperialGallons'// 81
  milliliters: 'milliliters'// 197
  liters: 'liters'// 82
  usGallons: 'usGallons'// 83
  cubicFeetPerSecond: 'cubicFeetPerSecond'// 142
  cubicFeetPerMinute: 'cubicFeetPerMinute'// 84
  cubicFeetPerHour: 'cubicFeetPerHour'// 191
  cubicMetersPerSecond: 'cubicMetersPerSecond'// 85
  cubicMetersPerMinute: 'cubicMetersPerMinute'// 165
  cubicMetersPerHour: 'cubicMetersPerHour'// 135
  imperialGallonsPerMinute: 'imperialGallonsPerMinute'// 86
  millilitersPerSecond: 'millilitersPerSecond'// 198
  litersPerSecond: 'litersPerSecond'// 87
  litersPerMinute: 'litersPerMinute'// 88
  litersPerHour: 'litersPerHour'// 136
  usGallonsPerMinute: 'usGallonsPerMinute'// 89
  usGallonsPerHour: 'usGallonsPerHour'// 192
  degreesAngular: 'degreesAngular'// 90
  degreesCelsiusPerHour: 'degreesCelsiusPerHour'// 91
  degreesCelsiusPerMinute: 'degreesCelsiusPerMinute'// 92
  degreesFahrenheitPerHour: 'degreesFahrenheitPerHour'// 93
  degreesFahrenheitPerMinute: 'degreesFahrenheitPerMinute'// 94
  jouleSeconds: 'jouleSeconds'// 183
  kilogramsPerCubicMeter: 'kilogramsPerCubicMeter'// 186
  kilowattHoursPerSquareMeter : 'kilowattHoursPerSquareMeter'// 137
  kilowattHoursPerSquareFoot: 'kilowattHoursPerSquareFoot'// 138
  megajoulesPerSquareMeter: 'megajoulesPerSquareMeter'// 139
  megajoulesPerSquareFoot: 'megajoulesPerSquareFoot'// 140
  noUnits: 'noUnits'// 95
  newtonSeconds: 'newtonSeconds'// 187
  newtonsPerMeter: 'newtonsPerMeter'// 188
  partsPerMillion: 'partsPerMillion'// 96
  partsPerBillion: 'partsPerBillion'// 97
  percent: 'percent'// 98
  percentObscurationPerFoot: 'percentObscurationPerFoot'// 143
  percentObscurationPerMeter: 'percentObscurationPerMeter'// 144
  percentPerSecond: 'percentPerSecond'// 99
  perMinute: 'perMinute'// 100
  perSecond: 'perSecond'// 101
  psiPerDegreeFahrenheit: 'psiPerDegreeFahrenheit'// 102
  radians: 'radians'// 103
  radiansPerSecond: 'radiansPerSecond'// 184
  revolutionsPerMinute: 'revolutionsPerMinute'// 104
  squareMetersPerNewton: 'squareMetersPerNewton'// 185
  wattsPerMeterPerDegreeKelvin : 'wattsPerMeterPerDegreeKelvin'// 189
  wattsPerSquareMeterDegreeKel : 'wattsPerSquareMeterDegreeKelvin'// 141
  perMille: 'perMille'// 207
  gramsPerGram: 'gramsPerGram'// 208
  kilogramsPerKilogram: 'kilogramsPerKilogram'// 209
  gramsPerKilogram: 'gramsPerKilogram'// 210
  milligramsPerGram: 'milligramsPerGram'// 211
  milligramsPerKilogram: 'milligramsPerKilogram'// 212
  gramsPerMilliliter: 'gramsPerMilliliter'// 213
  gramsPerLiter: 'gramsPerLiter'// 214
  milligramsPerLiter: 'milligramsPerLiter'// 215
  microgramsPerLiter: 'microgramsPerLiter'// 216
  gramsPerCubicMeter: 'gramsPerCubicMeter'// 217
  milligramsPerCubicMeter: 'milligramsPerCubicMeter'// 218
  microgramsPerCubicMeter: 'microgramsPerCubicMeter'// 219
  nanogramsPerCubicMeter: 'nanogramsPerCubicMeter'// 220
  gramsPerCubicCentimeter: 'gramsPerCubicCentimeter'// 221
  wattHoursPerCubicMeter: 'wattHoursPerCubicMeter'// 250
  joulesPerCubicMeter: 'joulesPerCubicMeter'// 251
  becquerels: 'becquerels'// 222
  kilobecquerels: 'kilobecquerels'// 223
  megabecquerels: 'megabecquerels'// 224
  gray: 'gray'// 225
  milligray: 'milligray'// 226
  microgray: 'microgray'// 227
  sieverts: 'sieverts'// 228
  millisieverts: 'millisieverts'// 229
  microsieverts: 'microsieverts'// 230
  microsievertsPerHour: 'microsievertsPerHour'// 231
  decibelsA: 'decibelsA'// 232
  nephelometricTurbidityUnit : 'nephelometricTurbidityUnit'// 233
  pH: 'pH'// 234
  gramsPerSquareMeter: 'gramsPerSquareMeter'// 235
  minutesPerDegreeKelvin: 'minutesPerDegreeKelvin'// 236
}

export const EngineeringUnits:EngineeringUnitsMap = {
  empty: null,
  metersPerSecondPerSecond: 'metersPerSecondPerSecond',
  squareMeters: 'squareMeters',
  squareCentimeters: 'squareCentimeters',
  squareFeet: 'squareFeet',
  squareInches: 'squareInches',
  currency1: 'currency1',
  currency2: 'currency2',
  currency3: 'currency3',
  currency4: 'currency4',
  currency5: 'currency5',
  currency6: 'currency6',
  currency7: 'currency7',
  currency8: 'currency8',
  currency9: 'currency9',
  currency10: 'currency10',
  milliamperes: 'milliamperes',
  amperes: 'amperes',
  amperesPerMeter: 'amperesPerMeter',
  amperesPerSquareMeter: 'amperesPerSquareMeter',
  ampereSquareMeters: 'ampereSquareMeters',
  decibels: 'decibels',
  decibelsMillivolt: 'decibelsMillivolt',
  decibelsVolt: 'decibelsVolt',
  farads: 'farads',
  henrys: 'henrys',
  ohms: 'ohms',
  ohmMeters: 'ohmMeters',
  ohmMeterPerSquareMeter: 'ohmMeterPerSquareMeter',
  milliohms: 'milliohms',
  kilohms: 'kilohms',
  megohms: 'megohms',
  microSiemens: 'microSiemens',
  millisiemens: 'millisiemens',
  siemens: 'siemens',
  siemensPerMeter: 'siemensPerMeter',
  teslas: 'teslas',
  volts: 'volts',
  millivolts: 'millivolts',
  kilovolts: 'kilovolts',
  megavolts: 'megavolts',
  voltAmperes: 'voltAmperes',
  kilovoltAmperes: 'kilovoltAmperes',
  megavoltAmperes: 'megavoltAmperes',
  ampereSeconds: 'ampereSeconds',
  ampereSquareHours: 'ampereSquareHours',
  voltAmpereHours: 'voltAmpereHours',
  kilovoltAmpereHours: 'kilovoltAmpereHours',
  megavoltAmpereHours: 'megavoltAmpereHours',
  voltAmperesReactive: 'voltAmperesReactive',
  kilovoltAmperesReactive: 'kilovoltAmperesReactive',
  megavoltAmperesReactive: 'megavoltAmperesReactive',
  voltAmpereHoursReactive: 'voltAmpereHoursReactive',
  kilovoltAmpereHoursReactive: 'kilovoltAmpereHoursReactive',
  megavoltAmpereHoursReactive: 'megavoltAmpereHoursReactive',
  voltsPerDegreeKelvin: 'voltsPerDegreeKelvin',
  voltsPerMeter: 'voltsPerMeter',
  voltsSquareHours: 'voltsSquareHours',
  degreesPhase: 'degreesPhase',
  powerFactor: 'powerFactor',
  webers: 'webers',
  joules: 'joules',
  kilojoules: 'kilojoules',
  kilojoulesPerKilogram: 'kilojoulesPerKilogram',
  megajoules: 'megajoules',
  joulesPerHours: 'joulesPerHours',
  wattHours: 'wattHours',
  kilowattHours: 'kilowattHours',
  megawattHours: 'megawattHours',
  wattHoursReactive: 'wattHoursReactive',
  kilowattHoursReactive: 'kilowattHoursReactive',
  megawattHoursReactive: 'megawattHoursReactive',
  btus: 'btus',
  kiloBtus: 'kiloBtus',
  megaBtus: 'megaBtus',
  therms: 'therms',
  tonHours: 'tonHours',
  joulesPerKilogramDryAir: 'joulesPerKilogramDryAir',
  kilojoulesPerKilogramDryAir: 'kilojoulesPerKilogramDryAir',
  megajoulesPerKilogramDryAir: 'megajoulesPerKilogramDryAir',
  btusPerPoundDryAir: 'btusPerPoundDryAir',
  btusPerPound: 'btusPerPound',
  joulesPerDegreeKelvin: 'joulesPerDegreeKelvin',
  kilojoulesPerDegreeKelvin: 'kilojoulesPerDegreeKelvin',
  megajoulesPerDegreeKelvin: 'megajoulesPerDegreeKelvin',
  joulesPerKilogramDegreeKelvin: 'joulesPerKilogramDegreeKelvin',
  newton: 'newton',
  cyclesPerHour: 'cyclesPerHour',
  cyclesPerMinute: 'cyclesPerMinute',
  hertz: 'hertz',
  kilohertz: 'kilohertz',
  megahertz: 'megahertz',
  perHour: 'perHour',
  gramsOfWaterPerKilogramDryAir: 'gramsOfWaterPerKilogramDryAir',
  percentRelativeHumidity: 'percentRelativeHumidity',
  micrometers: 'micrometers',
  millimeters: 'millimeters',
  centimeters: 'centimeters',
  kilometers: 'kilometers',
  meters: 'meters',
  inches: 'inches',
  feet: 'feet',
  candelas: 'candelas',
  candelasPerSquareMeter: 'candelasPerSquareMeter',
  wattsPerSquareFoot: 'wattsPerSquareFoot',
  wattsPerSquareMeter: 'wattsPerSquareMeter',
  lumens: 'lumens',
  luxes: 'luxes',
  footCandles: 'footCandles',
  milligrams: 'milligrams',
  grams: 'grams',
  kilograms: 'kilograms',
  poundsMass: 'poundsMass',
  tons: 'tons',
  gramsPerSecond: 'gramsPerSecond',
  gramsPerMinute: 'gramsPerMinute',
  kilogramsPerSecond: 'kilogramsPerSecond',
  kilogramsPerMinute: 'kilogramsPerMinute',
  kilogramsPerHour: 'kilogramsPerHour',
  poundsMassPerSecond: 'poundsMassPerSecond',
  poundsMassPerMinute: 'poundsMassPerMinute',
  poundsMassPerHour: 'poundsMassPerHour',
  tonsPerHour: 'tonsPerHour',
  milliwatts: 'milliwatts',
  watts: 'watts',
  kilowatts: 'kilowatts',
  megawatts: 'megawatts',
  btusPerHour: 'btusPerHour',
  kiloBtusPerHour: 'kiloBtusPerHour',
  horsepower: 'horsepower',
  tonsRefrigeration: 'tonsRefrigeration',
  pascals: 'pascals',
  hectopascals: 'hectopascals',
  kilopascals: 'kilopascals',
  pascalSeconds: 'pascalSeconds',
  millibars: 'millibars',
  bars: 'bars',
  poundsForcePerSquareInch: 'poundsForcePerSquareInch',
  millimetersOfWater: 'millimetersOfWater',
  centimetersOfWater: 'centimetersOfWater',
  inchesOfWater: 'inchesOfWater',
  millimetersOfMercury: 'millimetersOfMercury',
  centimetersOfMercury: 'centimetersOfMercury',
  inchesOfMercury: 'inchesOfMercury',
  degreesCelsius: 'degreesCelsius',
  degreesKelvin: 'degreesKelvin',
  degreesKelvinPerHour: 'degreesKelvinPerHour',
  degreesKelvinPerMinute: 'degreesKelvinPerMinute',
  degreesFahrenheit: 'degreesFahrenheit',
  degreeDaysCelsius: 'degreeDaysCelsius',
  degreeDaysFahrenheit: 'degreeDaysFahrenheit',
  deltaDegreesFahrenheit: 'deltaDegreesFahrenheit',
  deltaDegreesKelvin: 'deltaDegreesKelvin',
  years: 'years',
  months: 'months',
  weeks: 'weeks',
  days: 'days',
  hours: 'hours',
  minutes: 'minutes',
  seconds: 'seconds',
  hundredthsSeconds: 'hundredthsSeconds',
  milliseconds: 'milliseconds',
  newtonMeters: 'newtonMeters',
  millimetersPerSecond: 'millimetersPerSecond',
  millimetersPerMinute: 'millimetersPerMinute',
  metersPerSecond: 'metersPerSecond',
  metersPerMinute: 'metersPerMinute',
  metersPerHour: 'metersPerHour',
  kilometersPerHour: 'kilometersPerHour',
  feetPerSecond: 'feetPerSecond',
  feetPerMinute: 'feetPerMinute',
  milesPerHour: 'milesPerHour',
  cubicFeet: 'cubicFeet',
  cubicFeetPerDay: 'cubicFeetPerDay',
  cubicMeters: 'cubicMeters',
  cubicMetersPerDay: 'cubicMetersPerDay',
  imperialGallons: 'imperialGallons',
  milliliters: 'milliliters',
  liters: 'liters',
  usGallons: 'usGallons',
  cubicFeetPerSecond: 'cubicFeetPerSecond',
  cubicFeetPerMinute: 'cubicFeetPerMinute',
  cubicFeetPerHour: 'cubicFeetPerHour',
  cubicMetersPerSecond: 'cubicMetersPerSecond',
  cubicMetersPerMinute: 'cubicMetersPerMinute',
  cubicMetersPerHour: 'cubicMetersPerHour',
  imperialGallonsPerMinute: 'imperialGallonsPerMinute',
  millilitersPerSecond: 'millilitersPerSecond',
  litersPerSecond: 'litersPerSecond',
  litersPerMinute: 'litersPerMinute',
  litersPerHour: 'litersPerHour',
  usGallonsPerMinute: 'usGallonsPerMinute',
  usGallonsPerHour: 'usGallonsPerHour',
  degreesAngular: 'degreesAngular',
  degreesCelsiusPerHour: 'degreesCelsiusPerHour',
  degreesCelsiusPerMinute: 'degreesCelsiusPerMinute',
  degreesFahrenheitPerHour: 'degreesFahrenheitPerHour',
  degreesFahrenheitPerMinute: 'degreesFahrenheitPerMinute',
  jouleSeconds: 'jouleSeconds',
  kilogramsPerCubicMeter: 'kilogramsPerCubicMeter',
  kilowattHoursPerSquareMeter: 'kilowattHoursPerSquareMeter',
  kilowattHoursPerSquareFoot: 'kilowattHoursPerSquareFoot',
  megajoulesPerSquareMeter: 'megajoulesPerSquareMeter',
  megajoulesPerSquareFoot: 'megajoulesPerSquareFoot',
  noUnits: 'noUnits',
  newtonSeconds: 'newtonSeconds',
  newtonsPerMeter: 'newtonsPerMeter',
  partsPerMillion: 'partsPerMillion',
  partsPerBillion: 'partsPerBillion',
  percent: 'percent',
  percentObscurationPerFoot: 'percentObscurationPerFoot',
  percentObscurationPerMeter: 'percentObscurationPerMeter',
  percentPerSecond: 'percentPerSecond',
  perMinute: 'perMinute',
  perSecond: 'perSecond',
  psiPerDegreeFahrenheit: 'psiPerDegreeFahrenheit',
  radians: 'radians',
  radiansPerSecond: 'radiansPerSecond',
  revolutionsPerMinute: 'revolutionsPerMinute',
  squareMetersPerNewton: 'squareMetersPerNewton',
  wattsPerMeterPerDegreeKelvin: 'wattsPerMeterPerDegreeKelvin',
  wattsPerSquareMeterDegreeKel: 'wattsPerSquareMeterDegreeKelvin',
  perMille: 'perMille',
  gramsPerGram: 'gramsPerGram',
  kilogramsPerKilogram: 'kilogramsPerKilogram',
  gramsPerKilogram: 'gramsPerKilogram',
  milligramsPerGram: 'milligramsPerGram',
  milligramsPerKilogram: 'milligramsPerKilogram',
  gramsPerMilliliter: 'gramsPerMilliliter',
  gramsPerLiter: 'gramsPerLiter',
  milligramsPerLiter: 'milligramsPerLiter',
  microgramsPerLiter: 'microgramsPerLiter',
  gramsPerCubicMeter: 'gramsPerCubicMeter',
  milligramsPerCubicMeter: 'milligramsPerCubicMeter',
  microgramsPerCubicMeter: 'microgramsPerCubicMeter',
  nanogramsPerCubicMeter: 'nanogramsPerCubicMeter',
  gramsPerCubicCentimeter: 'gramsPerCubicCentimeter',
  wattHoursPerCubicMeter: 'wattHoursPerCubicMeter',
  joulesPerCubicMeter: 'joulesPerCubicMeter',
  becquerels: 'becquerels',
  kilobecquerels: 'kilobecquerels',
  megabecquerels: 'megabecquerels',
  gray: 'gray',
  milligray: 'milligray',
  microgray: 'microgray',
  sieverts: 'sieverts',
  millisieverts: 'millisieverts',
  microsieverts: 'microsieverts',
  microsievertsPerHour: 'microsievertsPerHour',
  decibelsA: 'decibelsA',
  nephelometricTurbidityUnit: 'nephelometricTurbidityUnit',
  pH: 'pH',
  gramsPerSquareMeter: 'gramsPerSquareMeter',
  minutesPerDegreeKelvin: 'minutesPerDegreeKelvin'

}
