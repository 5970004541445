<template>
  <div  style="flex:1;display: flex;  justify-content:center;align-items: center;flex-direction:column">
    <ElRow>
      <ElButton type="primary" size="large" style="width:400px " @click="OnCreateNew" >Create New</ElButton>
    </ElRow>
    <ElRow>
      <ElUpload ref="uploadRef" :auto-upload="true" action="/upload" :show-file-list="false" :http-request="OnUpload" >
        <ElButton type="primary" size="large" style="width:400px">Upload</ElButton>
      </ElUpload>
    </ElRow>
    <ElRow>
      <ElButton type="primary" size="large" style="width:400px">Duplicate from an Existing Profile</ElButton>
    </ElRow>
  </div>
</template>
<script setup lang="ts">
import api from '@/api/api'
import { ElMessage, ElUpload, emitChangeFn } from 'element-plus'
import { defineEmits } from 'vue'
import { NewProfileStore } from '@/store/newProfileStore'

const store = new NewProfileStore()

const emit = defineEmits(['selected'])

const OnUpload = async (options: any) => {
  const { file, onSuccess, onError } = options
  console.log(file)
  const formData = new FormData()
  formData.append('file', file)

  try {
    const response = await api.post('/profile/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    const profile = response.data.data
    store.Upload(profile)
    emit('selected')
  } catch (error:any) {
    onError(error)
    ElMessage.error('Unable to upload profile: ' + error.response?.data.message)
  }
}

const OnCreateNew = () => {
  store.New()
  emit('selected')
}
</script>
<style lang="scss" scoped>
.el-row {
  padding: 20px
}
</style>
