<template>
  <router-view />
</template>
<style lang="scss">
#app {
  font-family: Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  display: flex;
  background-color: var(--el-bg-color)
}
</style>
