<template>
  <BlockTitle title="LoRaWAN Parameters" description="Set the LoRaWAN Parameters" />
  <ElForm ref="lorawanFormRef" rules="lorawanFormRules" :model="lorawan" label-position="left" label-width="auto" style="width:600px" >
    <ElFormItem label="Region" >
      <ElSelect v-model="lorawan.region">
        <ElOption v-for="opt in RegionOptions()" :key="opt.value" :label="opt.label" :value="opt.value" />
      </ElSelect>

    </ElFormItem>
    <ElFormItem label="LoRaWAN MAC Version">
      <ElSelect v-model="lorawan.macVersion">
        <ElOption v-for="opt in MacVersionOptions()" :key="opt.value" :label="opt.label" :value="opt.value" />
      </ElSelect>
    </ElFormItem>

    <ElFormItem label="LoRaWAN Regional Parameters Revision">
      <ElSelect v-model="lorawan.regionalParametetsRevision">
        <ElOption v-for="opt in RegionalParametetsRevisionOptions()" :key="opt.value" :label="opt.label" :value="opt.value" />
      </ElSelect>
    </ElFormItem>
    <ElFormItem label="Supports OTAA">
      <ElSwitch v-model="lorawan.supportOTAA" />
    </ElFormItem>
    <ElFormItem label="Supports Class B">
      <ElSwitch v-model="lorawan.supportClassB" />
    </ElFormItem>
    <ElFormItem label="Supports Class C">
      <ElSwitch v-model="lorawan.supportClassC" />
    </ElFormItem>
    <ElFormItem label="Class C Downlink Timeout" v-if="lorawan.supportClassC">
      <ElInputNumber v-model="lorawan.classCDownlinkTimeout" />
    </ElFormItem>
  </ElForm>
</template>
<script setup lang="ts">
import BlockTitle from '@/components/BlockTitle.vue'
import { ElFormItem, ElSwitch, FormInstance, FormRules } from 'element-plus'
import { reactive, ref, defineExpose } from 'vue'
import { LoRaWANProfile, RegionOptions, RegionalParametetsRevisionOptions, MacVersionOptions } from '@/store/profile.interface'
import { NewProfileStore } from '@/store/newProfileStore'

const store = new NewProfileStore()

const lorawanFormRef = ref<FormInstance>()
const lorawanFormRules = ref<FormRules<LoRaWANProfile>>({

})

const lorawan = ref<LoRaWANProfile>(store.GetLoRaWAN())

const Submit = async () => {
  return new Promise<void>((resolve, reject) => {
    lorawanFormRef.value?.validate((isvalid, item) => {
      if (isvalid) {
        resolve()
      } else {
        reject(new Error('Some fields are invalid'))
      }
    })
  })
}

const Load = () => {
  lorawan.value = store.GetLoRaWAN()
}

defineExpose({ Submit, Load })
</script>
