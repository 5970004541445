<template>
  <BlockTitle title="Generic Settings" />
  <ElForm :model="meta" ref="metaFormRef" label-position="left" label-width="auto" :rules="metaRules" style="width:600px">

  <ElFormItem prop="model" label="Device Model" >
    <ElInput v-model="meta.model" :readonly="isEdit" />
  </ElFormItem>
  <ElFormItem prop="vendor" label="Vendor"  >
    <ElAutocomplete v-model="meta.vendor" clearable placeholder="Pleae input the device vendor"
      :fetch-suggestions="vendorSearch" :readonly="isEdit"
    />
  </ElFormItem>
  <ElFormItem prop="description" label="Feature Description" >
    <ElInput v-model="meta.description" type="textarea" rows="6" />
  </ElFormItem>
  <ElFormItem label="Device Picture">
    <ElUpload class="avatar-uploader" :auto-upload=true :show-file-list="false" :http-request="OnUploadImage" v-loading="imageUploading" >
      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
      <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
    </ElUpload>
  </ElFormItem>
  <ElFormItem label="Documents">
    <ElRow v-for="(name, idx) in meta.docs" :key="idx" style="width: 100%;align-items:center" >
      <ElIcon  :size="24" style="margin-right: 12px;" ><Document /></ElIcon>
      <ElText style="margin-right: 12px;">{{ name.substring(name.lastIndexOf('/') + 1) }}</ElText>
      <ElIcon :size="18" style="line-height: 24px;margin-right: 12px;color:red;cursor: pointer;" @click="meta.docs?.splice(idx, 1)"><CircleClose /></ElIcon>
    </ElRow>
    <ElRow style="width: 100%;">
      <ElUpload class="file-uploader" :auto-upload=true :show-file-list="false" :http-request="OnUploadDocument" v-loading="docUploading" >
        <el-icon  class="file-uploader-icon"><Plus /></el-icon>
      </ElUpload>
    </ElRow>
  </ElFormItem>
  </ElForm>
</template>
<script setup lang="ts">
import BlockTitle from './BlockTitle.vue'
import { ProfileMeta } from '@/store/profile.interface'
import { ElAutocomplete, ElFormItem, ElMessage, ElTooltip, ElUpload, FormInstance, FormRules } from 'element-plus'
import { reactive, ref, defineExpose, onMounted, computed } from 'vue'
import { Plus, Document, CircleClose } from '@element-plus/icons-vue'
import { NewProfileStore } from '@/store/newProfileStore'
import api from '@/api/api'
import resource from '@/api/resource'
import { v4 as uuid } from 'uuid'
import axios from 'axios'

const store = new NewProfileStore()
const metaFormRef = ref<FormInstance>()

const meta = ref(store.GetProfileMeta())
const imageUrl = ref('')
const imageUploading = ref(false)
const docUploading = ref(false)
const isEdit = ref(false)
const metaRules = ref<FormRules<ProfileMeta>>({
  model: { type: 'string', required: true },
  vendor: { type: 'string', required: true },
  description: { type: 'string', required: true }
})

const vendors = [{ value: 'RAKwireless' }, { value: 'Senso8' }]

const vendorSearch = (queryString: string, cb: any) => {
  const results = queryString
    ? vendors.filter((item) => {
      return item.value.toLowerCase().startsWith(queryString.toLowerCase())
    })
    : vendors
  // call callback function to return suggestions
  cb(results)
}

const doUpload = async (url:string, file:any) => {
  return new Promise<void>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = async () => {
      await axios.put(url, reader.result, {
        headers: {
          'Content-Type': file.type
        }
      }).then(() => {
        setTimeout(() => {
          resolve()
        }, 200)
      }).catch(err => {
        reject(err)
      })
    }

    reader.readAsArrayBuffer(file)
  })
}

const OnUploadDocument = async (options: any) => {
  const { file, onSuccess, onError } = options
  console.log(file)
  const formData = new FormData()
  formData.append('file', file)
  docUploading.value = true
  try {
    const response = await api.post('/resource/doc', {
      file: file.name,
      contentType: file.type,
      contentLength: file.size
    })

    const url = response.data.data
    await doUpload(url, file)
    meta.value.docs!.push(url.split('?')[0])
  } catch (error:any) {
    onError(error)
    ElMessage.error('Unable to upload profile: ' + error.response?.data.errorMessage)
  } finally {
    docUploading.value = false
  }
}

const OnUploadImage = async (options: any) => {
  const { file, onSuccess, onError } = options
  console.log('file is', file)

  try {
    imageUploading.value = true
    const response = await api.post('/resource/image', {
      file: file.name,
      contentType: file.type,
      contentLength: file.size
    })

    const url:string = response.data.data
    await doUpload(url, file)

    imageUrl.value = url.split('?')[0]
    meta.value.image = imageUrl.value
    onSuccess()
  } catch (error:any) {
    onError(error)
    ElMessage.error('Unable to upload profile: ' + error.message)
  } finally {
    imageUploading.value = false
  }
}
const Submit = async() => {
  return new Promise<void>((resolve, reject) => {
    metaFormRef.value!.validate((isvalid) => {
      if (isvalid) {
        store.SubmitMeta(meta.value).then(() => {
          return resolve()
        }).catch(err => {
          return reject(err)
        })
      } else {
        reject(new Error('Some fields are invalid'))
      }
    })
  })
}

const Load = () => {
  meta.value = store.GetProfileMeta()
  if (meta.value.model !== undefined) {
    isEdit.value = true
  }
  if (meta.value.image) { imageUrl.value = meta.value.image }
}

defineExpose({ Submit, Load })
onMounted(() => {
  Load()
})
</script>
<style lang="scss">
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: var(--el-border-color);
  width: 178px;
  height: 178px;
  text-align: center;
}

.file-uploader .file {
  width: 60px;
  height: 60px;
  display: block;
}

.file-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.file-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.file-uploader-icon {
  font-size: 18px;
  color: var(--el-border-color);
  width: 60px;
  height: 60px;
  text-align: center;
}
</style>
