import axios from 'axios'
import { useRouter } from 'vue-router'

// const baseURL = '/api'
const baseURL = 'https://asaguicc29.execute-api.ap-east-1.amazonaws.com/dev/v1'
const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
})

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized access - 401. Jump to login')
      window.location.href = '#/signin'
    }
    return Promise.reject(error)
  }
)

export default api
