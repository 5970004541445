
import { BACObjectTypeMap, EngineeringUnitsMap } from '@/store/bactype'

export interface DataPoint {
  channel?: number,
  name: string,
  type: BACObjectTypeMap[keyof BACObjectTypeMap],
  covIncrement?: number,
  fport?: number,
  updateInterval: number,
  units?: EngineeringUnitsMap[keyof EngineeringUnitsMap]
}

export interface ProfileMeta {
  model?: string,
  vendor?: string,
  docs?: string[],
  image?:string,
  description?:string,
}

export interface LoRaWanMacVersionMap {
  LORAWAN_1_0_0: 'LORAWAN_1_0_0';
  LORAWAN_1_0_1: 'LORAWAN_1_0_1';
  LORAWAN_1_0_2: 'LORAWAN_1_0_2';
  LORAWAN_1_0_3: 'LORAWAN_1_0_3';
  LORAWAN_1_0_4: 'LORAWAN_1_0_4';
  LORAWAN_1_1_0: 'LORAWAN_1_1_0';
}

export const LoRaWanMacVersion: LoRaWanMacVersionMap = {
  LORAWAN_1_0_0: 'LORAWAN_1_0_0',
  LORAWAN_1_0_1: 'LORAWAN_1_0_1',
  LORAWAN_1_0_2: 'LORAWAN_1_0_2',
  LORAWAN_1_0_3: 'LORAWAN_1_0_3',
  LORAWAN_1_0_4: 'LORAWAN_1_0_4',
  LORAWAN_1_1_0: 'LORAWAN_1_1_0'
}

export interface RegionalParametetsRevisionMap {
  A: 'A';
  B: 'B';
  RP002_1_0_0: 'RP002_1_0_0';
  RP002_1_0_1: 'RP002_1_0_1';
  RP002_1_0_2: 'RP002_1_0_2';
  RP002_1_0_3: 'RP002_1_0_3';
}

export const RegionalParametetsRevision: RegionalParametetsRevisionMap = {
  A: 'A',
  B: 'B',
  RP002_1_0_0: 'RP002_1_0_0',
  RP002_1_0_1: 'RP002_1_0_1',
  RP002_1_0_2: 'RP002_1_0_2',
  RP002_1_0_3: 'RP002_1_0_3'
}

interface AdrAlgorithmMap {
  LORA: 'LoRA Only'
}

export const ADR: AdrAlgorithmMap = {
  LORA: 'LoRA Only'
}

export interface RegionMap {
  EU433: 'EU433'
  CN470: 'CN470'
  IN865: 'IN865'
  RU864: 'RU864'
  EU868: 'EU868'
  AU915: 'AU915'
  US915: 'US915'
  KR920: 'KR920'
  AS923: 'AS923'
  AS923_2: 'AS923-2'
  AS923_3: 'AS923-3'
  AS923_4: 'AS923-4'
}

export const REGION: RegionMap = {
  EU433: 'EU433',
  CN470: 'CN470',
  IN865: 'IN865',
  RU864: 'RU864',
  EU868: 'EU868',
  AU915: 'AU915',
  US915: 'US915',
  KR920: 'KR920',
  AS923: 'AS923',
  AS923_2: 'AS923-2',
  AS923_3: 'AS923-3',
  AS923_4: 'AS923-4'
}

export const RegionOptions = (): { label : string, value: string }[] => {
  return Object.values(REGION).map(region => ({
    label: region,
    value: region
  }))
}

export const MacVersionOptions = (): { label : string, value: string }[] => {
  return Object.values(LoRaWanMacVersion).map(region => ({
    label: region,
    value: region
  }))
}

export const RegionalParametetsRevisionOptions = (): { label : string, value: string }[] => {
  return Object.values(RegionalParametetsRevision).map(region => ({
    label: region,
    value: region
  }))
}

export interface LoRaWANProfile {
  region: RegionMap[keyof RegionMap],
  macVersion: LoRaWanMacVersionMap[keyof LoRaWanMacVersionMap],
  regionalParametetsRevision: RegionalParametetsRevisionMap[keyof RegionalParametetsRevisionMap],
  adrAlgorithm: AdrAlgorithmMap[keyof AdrAlgorithmMap],
  supportClassB: boolean,
  supportClassC: boolean,
  supportOTAA: boolean,
  downlinkFPort?: number,
  classCDownlinkTimeout: number
}

export interface ProfileContent {
  model?: string,
  vendor?: string,
  description?: string,
  lorawan: LoRaWANProfile,
  datatype: Record<number, DataPoint>,
  codec?: string,
  profileVersion?: string,
  imageUrl?: string,
  docsUrl?: string[]
}

export interface ProfileStateMap {
  STATE_DRAFT: 'draft',
  STATE_PENDING: 'pending',
  STATE_APPROVED: 'approved'
}

export const ProfileState: ProfileStateMap = {
  STATE_DRAFT: 'draft',
  STATE_PENDING: 'pending',
  STATE_APPROVED: 'approved'
}

export interface Profile {
  id: string
  vendor?: string
  state?: ProfileStateMap[keyof ProfileStateMap]
  profile: ProfileContent
}
