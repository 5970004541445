<template>
<ElMenu :collapse=false class="side-menu">
  <ElMenuItem v-for="m in menu" :key="m.index" :index="m.index" @click="OnMenu(m)" >
  <ElIcon >
    <component :is="m.icon" />
  </ElIcon>
  <ElText>{{ m.label }}</ElText>
  </ElMenuItem>
</ElMenu>
</template>
<script  lang="ts" setup>
import { ElMenuItem, ElText, menuItemEmits } from 'element-plus'
import { Search, Edit, Plus, Minus, RefreshRight, Operation } from '@element-plus/icons-vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
interface MenuItem {
  index: string,
  label: string,
  icon?: any,
  router: string
}

const menu = ref<MenuItem[]>([{
  index: '1',
  label: 'My Profiles',
  icon: Operation,
  router: 'profiles'
}, {
  index: '2',
  label: 'Profile Browser',
  icon: Operation,
  router: 'browser'
}])

const OnMenu = (m:MenuItem) => {
  router.push({ name: m.router })
}

</script>
<style lang="scss" scoped>
.el-menu {
  margin-top: 80px
}
.side-menu .el-menu-item {
  padding-left: 40px !important;
}
</style>
